import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import FooterStyles from './footer.module.scss'
const Footer = () => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

    return (
        <footer className={FooterStyles.footer}>
            <p>Built by {data.site.siteMetadata.author}, © {new Date().getFullYear()}</p>
        </footer>
    )
}

export default Footer