// import { Link, graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"

import React, {useState} from "react"
import headerStyles from './header.module.scss'
import styled from 'styled-components'

const Navigation = styled.nav`

  @media (max-width: 600px) {
    display: flex;
    background-color: #fff;
    position: sticky;
    justify-content: space-between;
    text-transform: uppercase;
    border-bottom: 2px solid #33333320;
    margin: 0 auto;
    padding: 0 5vw;
    z-index: 2;
    align-self: center;
    height: 3rem;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
  }
`

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 10vw;

  @media (max-width: 600px) {
    display: flex;
  }
`

const Navbox = styled.div`


  @media (max-width: 600px) {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    border-left: solid;
    border-color: #33333320;
    flex-direction: column;
    position: fixed;
    width: 30%;
    justify-content: flex-start;
    padding-top: 1rem;
    background-color: #fff;
    transition: all 0.4s ease-in;
    top: 3rem;
    right: ${props => (props.open ? "-100%" : "0")};
  }
`

const Hamburger = styled.div`
  background-color: #111;
  width: 30px;
  height: 3px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #111;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`

const Header = () => {
  // const data = useStaticQuery(graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           title
  //         }
  //       }
  //     }
  //   `)

  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
   
    
    <Navigation>
    <header className={headerStyles.header}>
    <div className={headerStyles.navBar}>
       <h1>
           <Link className={headerStyles.title} to="/">
             ETHAN WU
           </Link>
         </h1> 

       </div>
       </header>


            <Toggle
      navbarOpen={navbarOpen}
      onClick={() => setNavbarOpen(!navbarOpen)}
    >
      {navbarOpen ? <Hamburger open /> : <Hamburger />}
    </Toggle>
    {navbarOpen ? (
      <Navbox>
    <div className={headerStyles.navBar}>

      <ul className={headerStyles.navList}>
      <li>
          <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}
            to="/"
          >
            HOME
      </Link>
        </li>

        <li>
          <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}
            to="/bio"
          >
            BIO
      </Link>
        </li>

        <li>
          <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}
            to="/projects"
          >
            PROJECTS
      </Link>
        </li>
        <li>
          <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}
            to="/blog"
          >
            BLOG
      </Link>
        </li>

        <li>
          <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}
            to="/contact"
          >
            CONTACT
      </Link>
        </li>
      </ul>
      </div>
      </Navbox>
    ) : (
      <Navbox open>
      <center>
      <ul className={headerStyles.navList}>
      <li>
          <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}
            to="/"
          >
            HOME
      </Link>
        </li>

        <li>
          <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}
            to="/bio"
          >
            BIO
      </Link>
        </li>

        <li>
          <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}
            to="/projects"
          >
            PROJECTS
      </Link>
        </li>
        <li>
          <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}
            to="/blog"
          >
            BLOG
      </Link>
        </li>

        <li>
          <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}
            to="/contact"
          >
            CONTACT
      </Link>
        </li>
      </ul>
      </center>
      
      </Navbox>
    )}
  </Navigation>


   


  // <header className={headerStyles.header}>
  //   <div className={headerStyles.navBar}>
  //      <h1>
  //       <Link className={headerStyles.title} to="/">
  //         ETHAN WU
  //       </Link>
  //     </h1>
  //       <ul className={headerStyles.navList}>
  //       <li>
  //           <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}
  //             to="/"
  //           >
  //             HOME
  //       </Link>
  //         </li>

  //         <li>
  //           <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}
  //             to="/bio"
  //           >
  //             BIO
  //       </Link>
  //         </li>

  //         <li>
  //           <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}
  //             to="/projects"
  //           >
  //             PROJECTS
  //       </Link>
  //         </li>
  //         <li>
  //           <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}
  //             to="/blog"
  //           >
  //             BLOG
  //       </Link>
  //         </li>

  //         <li>
  //           <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem}
  //             to="/contact"
  //           >
  //             CONTACT
  //       </Link>
  //         </li>
  //       </ul>
  //   </div>
  // </header>
  )
}

export default Header
